import React from "react";
import "../Styles/Styles.css";
import FooterLogo from "../Images/Images/UnicconLogo.png";
import Phone from "../Images/Images/phone.png";
import Message from "../Images/Images/message.png";
import Twitter from "../Images/Images/twitter.png";
import linkdin from "../Images/Images/linkdin.png";
import facebook from "../Images/Images/facebook.png";
import instagram from "../Images/Images/instagram.png";
import FooterLogoPhone from "../Images/Images/UnicconLogofootermobile.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="whatYouWillAllDivProTect" data-aos="zoom-in-down">
      <div className="ProTectHightFirst"></div>
      <div className="whatYouWillAllDivProTectFooterDesktopTablet">
        <div className="ProtectYourInformationFooter">
          <div className="ProtectYourInformationFootersubDiv">
            <div className="ProtectYourInformationFootersubDivSmallDivs">
              <img className="FooterLogoc" src={FooterLogo} alt="FooterLogo" />
              <div className="Weprovidesimple">
                <p>
                  We provide simple, scaleable, cybersecurity guidance and
                  services that help protects your business from cyber threats
                  and disruptions.
                </p>
              </div>
            </div>
            <div className="ProtectYourInformationFootersubDivSmallDivs">
              <div className="firstHtextFooter">
                <h2>Company</h2>
                <p>Privacy Policy</p>
                <p>Terms & Conditions</p>
              </div>
            </div>
            <div className="ProtectYourInformationFootersubDivSmallDivs">
              <div className="firstHtextFooter">
                <h2>Contact Us</h2>
                <div className="contactUsRow">
                  <div>
                    <img src={Phone} alt="phonelogo" />
                  </div>
                  <div className="contactusnumberdiv">
                    <p>+2349137160848 +2349131573923 +447441426012</p>
                  </div>
                </div>
                <div className="contactUsRow">
                  <div>
                    <img src={Message} alt="Messagelogo" />
                  </div>
                  <div className="contactusnumberdiv">
                    <p>
                      info@uniccongroup.com lossless@uniccongroup.com
                      sales@uniccongroup.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ProtectYourInformationFootersubDivSmallDivs">
              <div className="firstHtextFooter">
                <h2>Website Links</h2>
                {/* <p>Home</p> */}
                <p>Platform</p>
                <p>Blog</p>
                <p>Resources</p>
              </div>
            </div>
            <div className="ProtectYourInformationFootersubDivSmallDivs">
              <div className="firstHtextFooter">
                <h2>Location</h2>
                <p>3rd Floor, Lanre Shittu House, Mabushi, Abuja. 900108</p>
                <p>28 Kingshold Road, London, England, E97JF.</p>
              </div>
            </div>
          </div>
          <div className="footerHeight"></div>
          <div className="ProtectYourInformationFooterSocial">
            <div className="socialIconDiv">
              <div>
                <img src={Twitter} alt="Twitter" />
              </div>
              <div>
                <img src={linkdin} alt="linkdin" />
              </div>
              <div>
                <img src={facebook} alt="facebook" />
              </div>
              <div>
                <img src={instagram} alt="instagram" />
              </div>
            </div>
            <div className="footerHeight"></div>
            <div className="footerHorizontaline"></div>
            <div className="allrightsreserved">
              <p>© {currentYear} Uniccon. All rights reserved. </p>
            </div>
          </div>
        </div>
      </div>

      <div className="OnlyMobileViewsDiv">
        <div className="backgroundContainers">
          <div className="textOverlayPhone">
            <div className="textOverlayPhoneRow">
              <div className="textOverlayPhoneRowdiv">
                <div className="footerHeight"></div>

                <img
                  className="FootersLogocphone"
                  src={FooterLogoPhone}
                  alt="FooterLogo"
                />
                <div className="Wecombineworld">
                  <p>
                    We combine world class expertise, experience and modern
                    tools to scale up your productivity. Valuing and
                    collaborating for best services have kept us ahead of the
                    market.
                  </p>
                </div>
              </div>
              <div></div>
            </div>
            <div style={{ height: "80px" }}></div>
            <div className="contactUsCompany">
              <div className="contactUsCompanyFirstDiv">
                <h1>Contact Us</h1>
                <div className="contactUsCompanyFirstDivTwo">
                  <div>
                    <img src={Phone} alt="phonelogo" />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <p>+2349137160848 +2349131573923 +447441426012</p>
                  </div>
                </div>

                <div className="contactUsCompanyFirstDivTwo">
                  <div>
                    <img src={Message} alt="Messagelogo" />
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <p>
                      info@uniccongroup.com lossless@uniccongroup.com
                      sales@uniccongroup.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="contactUsCompanySecondDiv">
                <h1>Company</h1>
                <p>Privacy Policy</p>
                <p>Terms & Conditions</p>
              </div>
            </div>
            <div style={{ height: "50px" }}></div>

            <div className="contactUsCompany">
              <div className="contactUsCompanyFirstDiv">
                <h1>Website Links</h1>
                {/* <p>Home</p> */}
                <p>Our Services</p>
              </div>
              <div className="contactUsCompanySecondDiv">
                <h1>Location</h1>
                <p>3rd Floor, Lanre Shittu House, Mabushi, Abuja. 900108</p>
                <p>28 Kingshold Road, London, England, E97JF.</p>
              </div>
            </div>
            <div className="footerHeight"></div>

            <div className="lastPartFooterMobile">
              <div className="lastPartFooterMobileOne">
                <div>
                  <img src={Twitter} alt="Twitter" />
                </div>
                <div>
                  <img src={linkdin} alt="linkdin" />
                </div>
                <div>
                  <img src={facebook} alt="facebook" />
                </div>
                <div>
                  <img src={instagram} alt="instagram" />
                </div>
              </div>
              <div className="footerHorizontaline"></div>
              <div className="allrightsreserved">
                <p>© {currentYear} Uniccon. All rights reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
