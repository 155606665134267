import React, { useRef, useState } from "react";
import "../Styles/Styles.css";
import BackgroundImage from "../Images/Images/MaskgroupServicer.png";
// import ReactAngelLeft from "../Images/Images/Vectorleft.png";
// import ReactAngelRight from "../Images/Images/Vectorright.png";
import Marquee from "react-fast-marquee";

import ActiveIcon from "../Images/Images/EllipseActive.png";
import InactiveIcon from "../Images/Images/EllipseInative.png";
const dummyData = [
  {
    id: 1,
    imageUrl: require("../Images/Images/RectangleFirst.png"),
    title: "Vulnerability Assessment Penetration and Testing (VAPT)",
    text: "we carry out a comprehensive security assessment to help organizations identify and address vulnerabilities in their digital infrastructures. ",
  },
  {
    id: 2,
    imageUrl: require("../Images/Images/RectangleSecond.png"),
    title: "Security Operations Center (SOC)",
    text: "Our centralized Security Operations Center (SOC) is manned by proficient analysts leveraging cutting-edge technologies, tools, and methodologies to perpetually monitor networks, systems, and applications for any signs of suspicious activities and anomalies.",
  },
  {
    id: 3,
    imageUrl: require("../Images/Images/RectangleThired.png"),
    title: "Audit and Compliance",
    text: "we monitor and evaluate the adherence to cybersecurity standards, regulations, and best practices, identifying vulnerabilities, and implementing measures to mitigate risks.",
  },
  {
    id: 4,
    imageUrl: require("../Images/Images/RectangleFouth.png"),
    title: "Digital Forensics",
    text: "Our team of experts gathers, extracts, analyzes, and interprets digital evidence, including files, logs, and system data, to reconstruct and comprehend digital events. This enables us to effectively respond to cybercrime and other digital incidents with precision and accuracy.",
  },
];

const OurService = () => {
  const elementRef = useRef(null);
  // const [arrowDisable, setArrowDisable] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  // const handleHorizontalScroll = (element, speed, distance, step) => {
  //   let scrollAmount = -400;
  //   const slideTimer = setInterval(() => {
  //     element.scrollLeft += step;
  //     scrollAmount += Math.abs(step);
  //     if (scrollAmount >= distance) {
  //       clearInterval(slideTimer);
  //     }
  //     if (element.scrollLeft === 0) {
  //       setArrowDisable(true);
  //     } else {
  //       setArrowDisable(false);
  //     }
  //   }, speed);
  // };

  // const handleMouseScroll = (event) => {
  //   const delta = Math.sign(event.deltaY);
  //   elementRef.current.scrollLeft += delta * 40;
  // };

  const goToIndex = (index) => {
    setCurrentIndex(index);
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    if (touchStartX === null) {
      return;
    }

    const touchEndX = event.touches[0].clientX;
    const deltaX = touchEndX - touchStartX;

    if (Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        goToPrevious();
      } else {
        goToNext();
      }
      setTouchStartX(null);
    }
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === dummyData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? dummyData.length - 1 : prevIndex - 1
    );
  };

  return (
    <div
      className="AboutUsectionDiv"
      // onWheel={handleMouseScroll}
    >
      <div className="AboutUsectionDivtp">
        <div className="AboutUsSectionDivs">
          <div className="AboutUsSection">
            {/* <div style={{height: '50px'}}></div> */}
            <p>
              With our comprehensive cybersecurity services, you can rest
              assured knowing that your business is secure from cyber attacks.
            </p>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        <div>
          <img
            src={BackgroundImage}
            alt="Maskgroup2.png"
            className="BackgroundImage1"
          />

          <div className="img-container" ref={elementRef}>
            <Marquee
              gradient={false}
              speed={40}
              pauseOnHover={true}
              direction="left"
              style={{
                width: "100%",
                margin: "0 auto",
              }}
            >
              {dummyData.map((item, i) => (
                <div className="content" key={i} style={{ marginLeft: "20px" }}>
                  <img src={item.imageUrl} alt="ContentImage" />
                  <h2>{item.title}</h2>
                  <p>{item.text}</p>
                  <div style={{ height: "10px" }}></div>
                </div>
              ))}
            </Marquee>
          </div>
        </div>
        <div className="leftRightHight"></div>
        {/* <div className="rightLeftArrow">
          <div
            onClick={() => {
              handleHorizontalScroll(elementRef.current, 25, 100, -10);
            }}
            disabled={arrowDisable}
          >
            <img src={ReactAngelLeft} alt="ReactAngelLeft" />
          </div>

          <div
            onClick={() => {
              handleHorizontalScroll(elementRef.current, 25, 100, 10);
            }}
            disabled={arrowDisable}
          >
            <img src={ReactAngelRight} alt="ReactAngelRight" />
          </div>
        </div> */}
      </div>
      <div className="image-slider">
        <div style={{ height: "20px" }}></div>
        <div className="AboutUsSection">
          <p>
            With our comprehensive cybersecurity solutions, you can rest assured
            knowing that your business is secure from cyber attacks.
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${BackgroundImage})`,
            width: "375px",
            height: "500px",
          }}
        >
          <div
            className="image-container"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <img
              src={dummyData[currentIndex].imageUrl}
              alt={`Images ${currentIndex + 1}`}
              style={{ width: "323px", position: "relative" }}
            />
            <div className="content">
              <h2>{dummyData[currentIndex].title}</h2>
              <p>{dummyData[currentIndex].text}</p>
            </div>
          </div>
        </div>
        <div style={{ height: "20px" }}></div>
        <div className="indicators">
          <div className="indicator">
            {dummyData.map((image, index) => (
              <img
                key={image.id}
                className={`indicator ${
                  index === currentIndex ? "active" : ""
                }`}
                src={index === currentIndex ? ActiveIcon : InactiveIcon}
                alt={`Indicator ${index + 1}`}
                onClick={() => goToIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="leftRightHightBottom"></div>
    </div>
  );
};

export default OurService;
