import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Styles/Styles.css";
import BackgroundImage from "../Images/Images/Maskgroup2.png";
import Image1 from "../Images/Images/Rectangle1.png";
import Image2 from "../Images/Images/Rectangle2.png";
import Image3 from "../Images/Images/Rectangle3.png";
import Image4 from "../Images/Images/Rectangle4.png";
import Checkmark from "../Images/Images/checkmark-circle-fill.png";
import tablet from "../Images/Images/Group4.png";
import mobile from "../Images/Images/Group4mobil.png";
const WhatYouWillEnjoy = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="whatYouWillAllDiv" data-aos="zoom-in-down">
      <div className="whatYouWillDiv">
        <div className="whatYouWill">
          <h2>What You’ll Enjoy</h2>
          <div className="whatYouWillHorizontaline"></div>
        </div>
      </div>
      {/* <div className="whatYouWillHeight"></div> */}
      <div className="AboutUsSection AboutUsSectionw">
        <p>
          We provide simple, scaleable, and comprehensive cybersecurity guidance
          and solutions that help protects your business from cyber threats and
          disruptions.
        </p>
      </div>
      <div className="homeSection homeSectionp homeSectiontt istockphoto">
        <img
          className="istockphoto homeSectiontt"
          src={BackgroundImage}
          alt="BackgroundImage"
        />
        <div className="textOverlayWill">
          <div className="textOverlayWillSubDiv1">
            <div className="textOverlayWillSubDivsub">
              <div className="img1">
                <img src={Image1} alt="Image1" />
              </div>
              <div className="img2">
                <img src={Image2} alt="Image2" />
              </div>
            </div>
            <div className="textOverlayWillSubDivsub textOverlayWillSubDivsub1">
              <div className="img2">
                <img src={Image3} alt="Image3" />
              </div>
              <div className="img1">
                <img src={Image4} alt="Image4" />
              </div>
            </div>
          </div>
          <div className="mobilePictureOnly">
            <div>
              <div className="textOverlayWillSubDivsub">
                <img
                  style={{ width: "333px", height: "269.1px" }}
                  src={mobile}
                  alt="Image1"
                />
              </div>
            </div>
          </div>

          <div className="tabletPictureOnly">
            <div>
              <div className="textOverlayWillSubDivsub">
                <img src={tablet} alt="Image1" />
              </div>
            </div>
          </div>

          <div className="textOverlayWillSubDiv2">
            <div className="protect">
              <h2>
                Protect your business from cyber threats and minimize the risk
                of data breaches.
              </h2>
            </div>
            <div className="protect2">
              <div>
                <h1>.</h1>
              </div>
              <div>
                <p>
                  Avoid the high costs associated with recovering from a cyber
                  attack by investing in proactive cybersecurity measures. 
                </p>
              </div>
            </div>
            <div className="futuresDivHeight"></div>
            <div className="futuresDiv">
              <div className="futures">
                <p>Features</p>
              </div>
              <div className="futuresContent">
                <div className="futuresContent1">
                  <div className="CheckmarkDiv">
                    <img src={Checkmark} alt="Checkmark" />
                  </div>
                  <div>
                    <p>Advanced Threat Detection</p>
                  </div>
                </div>
                <div className="futuresContent1">
                  <div className="CheckmarkDiv">
                    <img src={Checkmark} alt="Checkmark" />
                  </div>
                  <div>
                    <p>Industry-Leearning Expertise</p>
                  </div>
                </div>
                <div className="futuresContent1">
                  <div className="CheckmarkDiv">
                    <img src={Checkmark} alt="Checkmark" />
                  </div>
                  <div>
                    <p>Customized Solutions</p>
                  </div>
                </div>
                <div className="futuresContent1">
                  <div className="CheckmarkDiv">
                    <img src={Checkmark} alt="Checkmark" />
                  </div>
                  <div>
                    <p>24/7 Monitoring & Suport</p>
                  </div>
                </div>
                <div className="futuresContent1">
                  <div className="CheckmarkDiv">
                    <img src={Checkmark} alt="Checkmark" />
                  </div>
                  <div>
                    <p>Business as Usual (BaU)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatYouWillEnjoy;
