import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HomeeSection from "./HomeeSection";
import OurService from "./OurService";
import WhatYouWillEnjoy from "./WhatYouWillEnjoy";
import TrustedbyOrganizations from "./TrustedbyOrganizations";
import ProtectYourInformation from "./ProtectYourInformation";
import Footer from "../Footer/Footer";

const Sections = ({ activeTab, setShowContact, setActiveTab }) => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 200,
    });
  }, []);

  return (
    <>
      <section id="home" className="section" data-aos="fade-down-right">
        <HomeeSection />
      </section>
      <section id="services" className="section" data-aos="zoom-in-down">
        <h2 className={activeTab === "services" ? "active" : ""}>
          <p className="AboutUsSectionh">Our Service</p>
        </h2>
        <OurService />
      </section>
      <section id="#" className="section">
        <WhatYouWillEnjoy />
      </section>
      <section id="#" className="section">
        <TrustedbyOrganizations />
      </section>
      <section id="#" className="section">
        <ProtectYourInformation
          setShowContact={setShowContact}
          setActiveTab={setActiveTab}
        />
      </section>
      <section id="#" className="section">
        <Footer />
      </section>
    </>
  );
};

export default Sections;
