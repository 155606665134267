import React, { useEffect } from "react";
import istockphoto from "../Images/Images/NewImage.png";
import "../Styles/Styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
const HomeeSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="homeSection">
      <img src={istockphoto} alt="istockphoto.png" />

      <div className="textOverlay">
        <div className="simlipfied">
          <h2 data-aos="fade-right">
            Secure your business from Cyber Attacks!
          </h2>
          <div className="provideTechnology">
            <p data-aos="fade-left">
              With cyber threats on the rise, businesses of all sizes need
              reliable protection to safeguard their valuable assets and
              sensitive data That's where we come in. With our comprehensive
              cybersecurity solutions, you can rest assured knowing that your
              business is secure from cyber attacks.
            </p>
          </div>
          <div className="desktopHeight"></div>
          <div className="getStarted">
            <p>Get Started</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeeSection;
