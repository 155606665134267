import React, {useState} from "react";
import "../Styles/Styles.css";
import UnicconLogo from "../Images/Svgs/UnicconLogo.svg";
import HamburgerOpen from "../Images/Images/menu.png";
import HamburgerClose from "../Images/Images/delete.png";

const NavBar = ({
  activeTab,
  setActiveTab,
  menuOpen,
  setMenuOpen,
  setShowContact,
}) => {
    const [overlayVisible, setOverlayVisible] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setMenuOpen(false);
    setShowContact(tab === "contact"); // Update showContact based on the tab clicked
    setOverlayVisible(false); // Close overlay when a tab is clicked
  };

  const handleContactClick = () => {
    setActiveTab("contact");
    setMenuOpen(false);
    setShowContact(true); // Ensure showContact is true when "Contact Us" is clicked
    setOverlayVisible(false); // Close overlay when "Contact Us" is clicked
  };

  const handleLogoClick = () => {
    setActiveTab("home"); // Set active tab to "Home" when logo is clicked
    setMenuOpen(false);
    setShowContact(false); // Ensure showContact is false when logo is clicked
    setOverlayVisible(false); // Close overlay when logo is clicked
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setOverlayVisible(!overlayVisible); // Toggle overlay visibility
  };

  return (
    <nav className="navbar">
      <div className="logo-container" onClick={handleLogoClick}>
        <div className="logo">
          <img src={UnicconLogo} alt="UnicconLogo" onClick={handleLogoClick} />
        </div>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <img
          className="dHandBuger"
          src={menuOpen ? HamburgerClose : HamburgerOpen}
          alt="Menu"
        />
      </div>
      <div className={`tabs ${menuOpen ? "open" : ""}`}>
        <a
          href="#home"
          className={activeTab === "home" ? "active" : ""}
          onClick={() => handleTabClick("home")}
        >
          Home
        </a>
        <a
          href="#services"
          className={activeTab === "services" ? "active" : ""}
          onClick={() => handleTabClick("services")}
        >
          Our Service
        </a>
        <div
          className={`contact onlyMobile ${
            activeTab === "contact" ? "active" : ""
          }`}
          onClick={handleContactClick}
        >
          <p>Contact Us</p>
        </div>
      </div>
      <div
        className={`contact onlyDesktop ${
          activeTab === "contact" ? "active" : ""
        }`}
        onClick={handleContactClick}
      >
        <p>Contact Us</p>
      </div>
      {overlayVisible && <div className="overlay"></div>}
      {/* {overlayVisible && <div className="overlay" onClick={toggleMenu}></div>} */}
    </nav>
  );
};

export default NavBar;
