import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Styles/Styles.css";

const ProtectYourInformation = ({ setActiveTab, setShowContact }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const handleContactClick = () => {
    setActiveTab("contact"); // Update the active tab to "contact"
    setShowContact(true); // Update the state to show only the ContactUs component
  };
  return (
    <div className="whatYouWillAllDivProTect" data-aos="zoom-in-down">
      <div className="ProTectHightFirst"></div>
      <div className="whatYouWillDiv">
        <div className="ProtectYourInformation">
          <div className="ProtectYourInformationDiv">
            <div className="ProtectYourInformationDiv1">
              <h2>
                <span className="protect-text">Protect</span> your information
                from
              </h2>
            </div>
            <div className="ProtectYourInformationDiv1">
              <h2>
                <span className="protect-text">threats</span> today!
              </h2>
            </div>
            <div className="contactusdivs">
              <div className="contactusp" onClick={handleContactClick}>
                <p>Contact Us</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectYourInformation;
