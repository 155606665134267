import React, { useEffect, useState, useRef, useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Styles/Styles.css";
import Dotteddown from "../Images/Images/dotteddownleft.png";
import Dottedupright from "../Images/Images/dottedupright.png";
import Ellipsefirst from "../Images/Images/Ellipsefirst.png";
import Ellipsesecond from "../Images/Images/Ellipsesecond.png";
import ReactAngelLeft from "../Images/Images/Vectorleft.png";
import ReactAngelRight from "../Images/Images/Vectorright.png";
import ActiveIconIndicator from "../Images/Images/EllipseActive.png";
import InactiveIconIndicator from "../Images/Images/EllipseInative.png";

const cardData = [
  {
    id: 1,
    name: "Chinedu Okoro",
    text: "I had an exceptional experience with UNICCON Cybersecurity's VAPT services! Their comprehensive assessment revealed vulnerabilities we were unaware of, enabling us to strengthen our defenses. I highly recommend their expertise.",
  },
  {
    id: 2,
    name: "Ngozi Adeleke",
    text: "The digital forensics team at UNICCON Cybersecurity saved our company from a potential disaster. Their swift response and meticulous investigation ensured the security of our data. We are truly grateful for their professionalism.",
  },
  {
    id: 3,
    name: "Emeka Onwuka",
    text: "UNICCON's SOC services have been invaluable in safeguarding our organization. Their proactive monitoring and rapid incident response have kept us ahead of cyber threats. Their service is excellent!",
  },
  {
    id: 4,
    name: "Aisha Ibrahim",
    text: "As a compliance officer, I rely on UNICCON Cybersecurity for audit & compliance services. They have helped us navigate regulatory requirements seamlessly, ensuring our adherence to industry standards. I am highly impressed with their expertise!",
  },
  {
    id: 5,
    name: "Abimbola Adeyemi",
    text: "UNICCON's cybersecurity awareness training was insightful and practical. The modules were well-structured, covering essential topics such as phishing and password security. I feel more empowered to protect our organization's data after completing the training.",
  },
];

const TrustedbyOrganizations = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef(null);

  const handleNext = useCallback(() => {
    if (selectedCard < cardData.length - 1) {
      setSelectedCard(selectedCard + 1);
    } else {
      setSelectedCard(0);
    }
  },[selectedCard]);

  const handlePrevious = () => {
    if (selectedCard > 0) {
      setSelectedCard(selectedCard - 1);
    } else {
      setSelectedCard(0);
    }
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    if (touchStartX === null) {
      return;
    }

    const touchEndX = event.touches[0].clientX;
    const deltaX = touchEndX - touchStartX;

    if (Math.abs(deltaX) > 50) {
      // Adjust the threshold as needed
      if (deltaX > 0) {
        handlePrevious();
      } else {
        handleNext();
      }
      setTouchStartX(null);
    }
  };
  // const togglePause = () => {
  //   setIsPaused((prevPaused) => !prevPaused);
  // };

  useEffect(() => {
    AOS.init();

    intervalRef.current = setInterval(() => {
      if (!isPaused) {
        handleNext();
      }
    }, 2000);

    return () => clearInterval(intervalRef.current);
  }, [selectedCard, isPaused, handleNext]);

  const handleIndicatorClick = (index) => {
    setSelectedCard(index);
    clearInterval(intervalRef.current);
    setIsPaused(true);
  };

    const handleMouseEnter = () => {
      clearInterval(intervalRef.current);
      setIsPaused(true);
    };

const handleMouseLeave = () => {
  if (!isPaused) {
    intervalRef.current = setInterval(() => {
      handleNext();
    }, 2000);
  } else {
    setIsPaused(false);
  }
};



  return (
    <div className="whatYouWillAllDiv" data-aos="fade-right">
      <div className="whatYouWillDiv">
        <div className="whatYouWillTrusted">
          <h2>Trusted by Organizations</h2>
          <div className="whatYouWillHorizontaline"></div>
        </div>
      </div>
      <div className="whatYouWillHeightTrusted"></div>
      <div className="AboutUsSection">
        <p>
          We provide simple, scaleable, and comprehensive cybersecurity guidance
          and services that help protects your business from cyber threats and
          disruptions.
        </p>
      </div>
      <div
        className="weProvide"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={Dottedupright}
          alt="Dottedupright"
          className="Dottedupright"
        />
        <img src={Dotteddown} alt="Dotteddown" className="Dotteddown" />
        <div className="weProvideCenter">
          <div className="weProvidesimidiv"></div>
        </div>
        <div className="weProvideCenter">
          <div
            className="weProvideSimiCenter"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div className="weProvideSimiCenterCrnter">
              <div className="provisample">
                {cardData.map((card, index) => (
                  <div
                    key={index}
                    style={{
                      display: index === selectedCard ? "block" : "none",
                    }}
                  >
                    <p
                      style={{
                        // display: index === selectedCard ? "block" : "none",
                        fontWeight: "700",
                        // fontSize: '30px'
                      }}
                      data-aos={index === selectedCard ? "zoom-in-down" : ""}
                    >
                      {card.name}
                    </p>
                    <p
                      // style={{
                      //   display: index === selectedCard ? "block" : "none",
                      // }}
                      data-aos={index === selectedCard ? "zoom-in-down" : ""}
                    >
                      {card.text}
                    </p>
                  </div>
                ))}
              </div>
              <div className="EllipsClass">
                <div>
                  <img src={Ellipsefirst} alt="Ellipsefirst" />
                </div>
                <div>
                  <img src={Ellipsesecond} alt="Ellipsesecond" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="leftRightHightt"></div>
      <div className="rightLeftArrowt">
        <div onClick={handlePrevious}>
          <img src={ReactAngelLeft} alt="ReactAngelLeft" />
        </div>

        <div onClick={handleNext}>
          <img src={ReactAngelRight} alt="ReactAngelRight" />
        </div>
      </div>
      <div className="EllipsClassMobile">
        {cardData.map((card, index) => (
          <img
            key={index}
            src={
              index === selectedCard
                ? ActiveIconIndicator
                : InactiveIconIndicator
            }
            alt={`Indicator ${index}`}
            style={{ width: "12px", height: "12px" }}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
      <div className="whatHeght"></div>
    </div>
  );
};

export default TrustedbyOrganizations;
