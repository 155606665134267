import React, { useState } from "react";
import "../Styles/Styles.css";
import NavBar from "../NavBar/NavBar";
import Sections from "../Sections/Sections";
import ContactUs from "../ContactUs/ContactUs";

const Home = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [menuOpen, setMenuOpen] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setShowContact(tab === "contact");
    setMenuOpen(false);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NavBar
          activeTab={activeTab}
          setActiveTab={handleTabClick}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          setShowContact={setShowContact}
        />
      </div>
      {!showContact && (
        <Sections
          activeTab={activeTab}
          showContact={showContact}
          setShowContact={setShowContact}
          setActiveTab={setActiveTab}
        />
      )}
      {showContact && <ContactUs />}
      {modalOpen && 
        <div
         className="regModalDiv"
        >
          <div onClick={()=>{
            setModalOpen(false)
          }} className="regXiconDiv">
            <img src="/xicon.svg" alt="" />
          </div>
          <div
            className="modalContentDiv"
          >
            <div
            className="regModalTextDiv"
            >
              <h2 className="modalText1">Ready To Outsmart Cyber Attackers?  Explore Cybersecurity from the Attacker’s Perspective </h2>
              <h3 className="modalText2">
                Join our expert-led cybersecurity webinar which aims to arm you with the tips and tricks of cyber criminals, & show you how to live secured digitally. 
              </h3>
              <p className="modalText3">
                Have you ever been a victim of cyber-attacks? Maybe not! OR Maybe, Yes! In this webinar, we will show you practical ways you can cyber-proof your digital presence; whether yours or that of your organization, 
              </p>
              <p className="modalText4">“Click on the Button below to register for 
this webinar”</p>
              <div className="getStarted modalButton"  onClick={()=>{
                  setActiveTab("contact");
                  setMenuOpen(false);
                  setShowContact(true);
                  setModalOpen(false)
                }}>
                <p>Register for free</p>
              </div>
            </div>
            <div className="modalLockImage">
              <img style={{width: "100%"}} src="/lockImage.png" alt=""  />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Home;
