import React, { useState } from "react";
import "../Styles/Styles.css";
import Footer from "../Footer/Footer";
import ContactUsImage from "../Images/Images/contactUsImage.png";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);

  const {
    register,
    handleSubmit,
    //  reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
   
      setLoadingStatus(true);
      //  Send form data to Formspree
      const response = await fetch("https://formspree.io/f/xzbnanjr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
           Swal.fire({
             title: "Success!",
             text: "Form submitted successfully!",
             icon: "success",
           });
        setLoadingStatus(false);
        // Optionally, you can reset the form after submission
        // e.target.reset();
      } else {
        console.error("Form submission failed:", response.statusText);
        setLoadingStatus(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoadingStatus(false);
    }
  };

  return (
    <section id="contact" className="section">
      <div className="AllDivContactUs">
        <p className="ImageCaption">Your Business is Secured with Us!</p>
        <div className="ContactUsImageDiv">
          <img src={ContactUsImage} alt="ContactUsImage" />
        </div>
        <div className="SecondContactUsImageDiv">
          <div className="secondContactUsdiv">
            <h1>Leave a message</h1>
          </div>
          <div className="secondContactUsdivForms">
            <form
              action="https://formspree.io/f/xzbnanjr"
              onSubmit={handleSubmit(onSubmit)}
              method="post"
            >
              <div>
                <div className="form">
                  <div>
                    <p className="names">Full Name</p>
                    <input
                      className="InputField"
                      type="text"
                      name="name"
                      {...register("name", {
                        required: "Name is required.",
                        minLength: {
                          value: 2,
                          message: "Name should be at-least 2 characters.",
                        },
                      })}
                    />
                  </div>
                  {errors.name && (
                    <p style={{ color: "red" }}>{errors.name.message}</p>
                  )}

                  <div>
                    <p className="names">Phone Number</p>
                    <input
                      className="InputField"
                      type="number"
                      name="phone"
                      {...register("phone", {
                        required: "Phone is required.",
                      })}
                    />
                  </div>
                </div>
                {errors.phone && (
                  <p style={{ color: "red" }}>{errors.phone.message}</p>
                )}
                <div className="form">
                  <div>
                    <p className="names">Email</p>
                    <input
                      className="InputField"
                      type="text"
                      name="email"
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          message: "Email is not valid.",
                        },
                      })}
                    />
                  </div>
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email.message}</p>
                  )}
                </div>
                <div>
                  <p className="names">Message</p>
                  <textarea
                    className="InputFieldMessage"
                    type="text"
                    name="message"
                    {...register("message", {
                      required: "Message is required.",
                      minLength: {
                        value: 3,
                        message: "Message should be at-least 3 characters.",
                      },
                    })}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {errors.message && (
                    <p style={{ color: "red" }}>{errors.message.message}</p>
                  )}
                </div>

                <div>
                  <p></p>
                </div>
              </div>
              <div style={{ height: "30px" }}></div>
              <div className="submitdiv">
                <div
                  onClick={handleSubmit(onSubmit)}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#0d6efd";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#0d6efd";
                  }}
                  className="contactButton"
                >
                  {loadingStatus ? (
                    <p>sending</p>
                  ) : (
                    <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "0 auto",
                        cursor: "pointer",
                      }}
                    >
                      Contact Us
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="onlymobileViewFooterHightGap"></div>
      <Footer />
    </section>
  );
};

export default ContactUs;
